<template>
  <div>
    <div>
      <div class="row">
        <div class="col-9">
          <label for="exampleFormControlSelect1"
            ><h5>
              *
              <span
                >학생 목록<span style="font-size: 12px">(가나다순)</span></span
              >
            </h5></label
          >
        </div>
        <div class="col-3" style="text-align: right">
          <span
            ><button
              type="button"
              class="btn btn-primary btn-sm"
              @click="AddMember"
            >
              등록
            </button>
          </span>
        </div>
      </div>

      <div>
        <select
          class="form-control"
          id="gradegroup"
          name="gradegroup"
          v-model="gradegroup"
          @change="getGradeGroup()"
          v-show="this.role < 5"
        >
          <option id="" value="00">조회 대상을 선택하세요.</option>
          <option
            v-for="gradelist in gradegrouplist"
            :key="gradelist.grade"
            v-bind:value="gradelist.grade"
            :id="gradelist.grade"
          >
            {{ gradelist.gradename }}
          </option>
        </select>
      </div>
      <p></p>
      <div class="tab-content" id="myTabContent">
        <!-- 부서(팀)검색 -->
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <!-- 리스트 시작 -->
          <div
            v-if="getManageList.length > 0"
            class="accordion"
            id="accordionExample"
          >
            <div
              class="card"
              v-for="manageList in getManageList"
              :key="manageList.no"
            >
              <div class="card-header" :id="'headingOne' + manageList.no">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapseOne' + manageList.no"
                    aria-expanded="true"
                    :aria-controls="'#collapseOne' + manageList.no"
                    style="text-decoration-line: none"
                  >
                    <!-- <span style="color: #1d2124">
                         </span
                      ><span style="color: #dee2e6">|</span> -->
                    {{ manageList.name }}({{ manageList.sex }})
                    <span style="color: #dee2e6">|</span>
                  </button>
                </h2>
              </div>
              <div
                :id="'collapseOne' + manageList.no"
                class="collapse"
                :aria-labelledby="'headingOne' + manageList.no"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item" style="background: ghostwhite">
                      <div class="row">
                        <div class="col" style="font-size: 20px">
                          <b>{{ manageList.name }} </b>
                        </div>
                        <div
                          v-if="manageList.photo !== null"
                          class="col"
                          style="text-align: right"
                          @click="updatePhoto(manageList)"
                        >
                          <img
                            :src="photourl(manageList.photo)"
                            alt="..."
                            class="img-thumbnail"
                            style="width: 35%"
                          />
                        </div>
                        <div
                          v-else
                          class="col"
                          style="text-align: right"
                          @click="updatePhoto(manageList)"
                        >
                          <img src="../assets/noimage.png" style="width: 20%" />
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <b>1. 학년:</b>
                      {{ manageList.gradename }}
                    </li>
                    <li class="list-group-item">
                      <b>2. 생년월일:</b>
                      {{ manageList.birthday }}
                    </li>
                    <li class="list-group-item">
                      <b>3. 연락처</b>: {{ manageList.phone }}
                    </li>
                    <!-- <li class="list-group-item">3. 전파자: {{manageList.spreader}}</li> -->
                    <li class="list-group-item">
                      <b>4. 학부모 연락처</b>: {{ manageList.parentphone }}
                    </li>
                    <li class="list-group-item">
                      <b>5. 주소</b>: {{ manageList.address }}
                    </li>
                    <li class="list-group-item">
                      <b>6. 학교</b>: {{ manageList.school }}
                    </li>
                    <li class="list-group-item">
                      <b>7. 참고사항</b>: {{ manageList.rmk }}
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col" style="text-align: right">
                          <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            @click="UpdateMember(manageList)"
                          >
                            수정
                          </button>
                          <!-- <button type="button"  class="btn btn-warning btn-sm"  @click="updatePhoto(manageList)" style="margin-left: 13px;">사진등록</button> -->
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="accordion" id="accordionExample">
            <div class="card">
              <h2 class="mb-0">
                <button class="btn btn-block text-left">
                  조회된 학생 자료가 없습니다.
                </button>
              </h2>
            </div>
          </div>
          <!-- 리스트 종료 -->
          <!-- pgaination -->
          <!-- <div style="padding-top: 20px">
              <paginate
                :pageCount="pageTotalCount"
                :clickHandler="clickCallback"
                :prevText="'Prev'"
                :nextText="'Next'"
                :containerClass="'pagination'"
              >
              </paginate>
            </div> -->
          <!-- pgaination -->
        </div>
      </div>
    </div>
    <!-- 등록/수정 -->
    <div
      class="modal fade"
      id="memberAddModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="memberAddModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="memberAddModalLabel"
              style="color: blue"
            >
              <span id="plpmo"></span>
              <div v-if="isNew">학생 신규 등록</div>
              <div v-else>학생 정보 수정</div>
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <form
                id="addMemberform"
                name="addMemberform"
                v-on:submit="addMemberForm"
              >
                <!-- 선생님 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>선생님</h6>
                    </div>
                    <div class="col-9">
                      <select
                        class="form-control"
                        id="formteacherid"
                        name="formteacherid"
                        v-model="form.teacherid"
                        style="width: 80%"
                        :disabled="role > 1 && !isNew"
                      >
                        <option
                          v-for="teacher in teachers"
                          :key="teacher.id"
                          v-bind:value="teacher.teacherid"
                          :id="teacher.id"
                        >
                          {{ teacher.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- 학년 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>학년</h6>
                    </div>
                    <div class="col-9">
                      <select
                        class="custom-select"
                        id="formgroupid"
                        name="formgroupid"
                        v-model="form.groupid"
                        style="width: 60%"
                        :disabled="role > 1 && !isNew"
                      >
                        <option value="1">1학년</option>
                        <option value="2">2학년</option>
                        <option value="3">3학년</option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- 반 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>반</h6>
                    </div>
                    <div class="col-9">
                      <select
                        class="custom-select"
                        id="formgradediv"
                        name="formgradediv"
                        v-model="form.gradediv"
                        style="width: 60%"
                        :disabled="role > 1 && !isNew"
                      >
                        <option value="1">1반</option>
                        <option value="2">2반</option>
                        <option value="3">3반</option>
                        <option value="4">4반</option>
                        <option value="5">5반</option>
                        <option value="0">새가족</option>
                        <option value="9">선생님</option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- 이름 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>이름</h6>
                    </div>
                    <div class="col-9">
                      <input
                        type="text"
                        class="form-control"
                        id="formname"
                        v-model="form.name"
                        name="formname"
                        autocomplete="off"
                        placeholder=""
                        style="width: 60%"
                      />
                    </div>
                  </div>
                </div>

                <!-- 성별 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>성별</h6>
                    </div>
                    <div class="col-9">
                      <select
                        class="custom-select"
                        id="formsex"
                        name="formsex"
                        v-model="form.sex"
                        style="width: 40%"
                      >
                        <option value="남">남</option>
                        <option value="여">여</option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- 생년월일 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>생년월일</h6>
                    </div>
                    <div class="col-9">
                      <input
                        type="text"
                        class="form-control"
                        id="formbirthday"
                        v-model="form.birthday"
                        name="formmemberno"
                        autocomplete="off"
                        placeholder="ex) 20041230"
                        style="width: 60%"
                      />
                    </div>
                  </div>
                </div>
                <!-- 연락처 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>연락처</h6>
                    </div>
                    <div class="col-9">
                      <input
                        type="text"
                        class="form-control"
                        id="formphone"
                        v-model="form.phone"
                        name="formphone"
                        autocomplete="off"
                        placeholder="010-0000-0000"
                        style="width: 60%"
                      />
                    </div>
                  </div>
                </div>
                <!-- 학부모 연락처 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-4">
                      <h6>부모연락처</h6>
                    </div>
                    <div class="col-8">
                      <input
                        type="text"
                        class="form-control"
                        id="formparentphone"
                        v-model="form.parentphone"
                        name="formparentphone"
                        autocomplete="off"
                        placeholder="010-0000-0000"
                        style="width: 80%"
                      />
                    </div>
                  </div>
                </div>
                <!-- 주소 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>주소</h6>
                    </div>
                    <div class="col-9">
                      <textarea
                        type="text"
                        class="form-control"
                        id="formaddress"
                        name="formaddress"
                        v-model="form.address"
                        autocomplete="off"
                        placeholder=""
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <!-- 학교 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>학교</h6>
                    </div>
                    <div class="col-9">
                      <input
                        type="text"
                        class="form-control"
                        id="formschool"
                        name="formschool"
                        v-model="form.school"
                        autocomplete="off"
                        placeholder=""
                        style="width: 80%"
                      />
                    </div>
                  </div>
                </div>
                <!-- 참고사항 -->
                <div class="form-group mx-sm-3 mb-2">
                  <div class="row" style="align-items: baseline">
                    <div class="col-3">
                      <h6>참고사항</h6>
                    </div>
                    <div class="col-9">
                      <textarea
                        type="text"
                        class="form-control"
                        id="formrmk"
                        name="formrmk"
                        v-model="form.rmk"
                        autocomplete="off"
                        placeholder=""
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm">
                        <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          data-toggle="modal"
                          data-target="#memberDeleteModal"
                        >
                          탈퇴처리
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          v-if="isNew"
                          @click="setMember(1)"
                          style="margin-left: 20px"
                        >
                          등록
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          v-else
                          @click="setMember(2)"
                          style="margin-left: 20px"
                        >
                          수정
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          data-dismiss="modal"
                          @click="modalClose"
                          style="margin-left: 20px"
                        >
                          닫기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 사진 등록 -->
    <div
      class="modal fade"
      id="memberPhotoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="memberPhotoModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="memberPhotoModalLabel"
              style="color: blue"
            >
              <span id="plpmo"></span>
              <div>{{ photoitem.name }} 사진 등록</div>
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <!-- 선생님 -->
              <!-- 참고사항 -->
              <div
                v-if="photoitem.photo !== null"
                class="form-group mx-sm-3 mb-2"
              >
                <img
                  :src="photourl(photoitem.photo)"
                  alt="..."
                  class="img-thumbnail"
                />
              </div>
              <div
                v-else
                class="form-group mx-sm-3 mb-2"
                style="text-align: center"
              >
                <img src="../assets/noimage.png" style="width: 20%" />
              </div>
              <div class="mb-3">
                <form ref="addFileForm">
                  <input
                    type="file"
                    ref="files"
                    @change="fileUpload"
                    class="form-control"
                    style="height: 45px"
                  />

                  <div v-if="fileArray.length > 0">
                    <div
                      class="ml-2 mt-1 d-flex justify-content-between"
                      v-for="(data, index) in fileArray"
                      :key="'file' + index"
                    ></div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="savePhoto()"
                >
                  저장
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  @click="modalClose"
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 탈퇴 모달 -->
    <div
      class="modal fade"
      id="memberDeleteModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">탈퇴 처리</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">탈퇴 처리하시겠습니까?</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              data-dismiss="modal"
            >
              닫기
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="removemember(form)"
            >
              탈퇴 요청
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import manageService from "../services/manageService";
  //import moment from "moment";
  import $ from "jquery";
  import { mapState } from "vuex";

  export default {
    name: "list",
    components: {},
    data() {
      return {
        manageLists: {},
        page: 40, //보여줄 항목
        current_page: 0,
        manageListLimit: {},
        pageTotalCount: 0,
        form: {
          name: "",
          sex: "",
          birthday: "",
          phone: "",
          groupid: "",
          gradediv: "",
          parentphone: "",
          address: "",
          rmk: "",
          school: "",
          no: "",
          teacherid: this.$store.state.teacherid,
          grade: this.$store.state.grade,
          classid: this.$store.state.classid,
        },
        isNew: true,
        teachers: {},
        photoitem: {},
        file: "",
        fileArray: [],
        id: this.$store.state.id,
        grade: this.$store.state.grade,
        phone: this.$store.state.phone,
        name: this.$store.state.name,
        teacherid: this.$store.state.teacherid,
        role: this.$store.state.role,
        token: this.$store.state.token,
        classid: this.$store.state.classid,
        gradegrouplist: {},
        gradegroup: "00",
      };
    },
    computed: {
      ...mapState(["isLogin", "userInfo"]),
      getManageList: function () {
        return this.manageLists;
      },

      photourl() {
        return (url) => {
          return "./upload/photo/" + url;
        };
      },
    },
    methods: {
      removemember(fform) {
        const deldata = {
          no: fform.no,
          name: fform.name,
        };

        manageService
          .memberDelete(deldata)
          .then((response) => {
            if (response.data) {
              this.$toast.show("탈퇴 처리되었습니다.", {
                type: "success",
                position: "top-right",
              });
            } else {
              this.$toast.show("취소가 실패했습니다.", {
                type: "warning",
                position: "top-right",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });

        $("#memberDeleteModal").modal("hide");
        $("#memberAddModal").modal("hide");
        this.getMgmtAllList(this.gradegroup);
      },
      getGradeGroup() {
        this.getMgmtAllList(this.gradegroup);
      },
      getGradeGroupList() {
        const ndata = {
          classid: this.classid,
        };

        // 학년 반을 선택하세요.
        manageService
          .getGradeListAll(ndata)
          .then((response) => {
            //this.manageLists = response.data;
            this.gradegrouplist = response.data;
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      fileUpload(event) {
        this.fileArray.push(event.target.files[0]);
      },
      updatePhoto(item) {
        //사진 팝업
        this.photoitem = item;
        //this.$emit('memberPhotoModal',item);
        $("#memberPhotoModal").modal("show");
      },

      savePhoto() {
        //사진 저장
        const form = new FormData();
        for (const i of Object.keys(this.fileArray)) {
          form.append("file" + i, this.fileArray[i]);
        }

        // form.append('file', this.file);
        form.append("no", this.photoitem.no);

        form.forEach(function (key, value) {
          console.log(key, value);
        });
        console.log("result=", this.fileArray.length);
        if (this.fileArray.length < 1) {
          this.$toast.show("파일을 선택해주세요.", {
            type: "warning",
            position: "top-right",
          });
          return;
        }
        manageService
          .photoUpload(form, {
            header: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((result) => {
            console.log("result=", result);
            this.$toast.show("사진이 등록되었습니다.", {
              type: "success",
              position: "top-right",
            });
            this.getMgmtAllList(this.form.grade);
            $("#memberPhotoModal").modal("hide");
          });
      },
      //모달 닫기
      modalClose() {
        this.file = "";
        this.fileArray = [];
        this.formreset(); //초기화
      },
      // 신규 추가
      AddMember() {
        this.isNew = true;

        $("#memberAddModal").modal("show");
      },
      //전체 리스트 가져오기
      getMgmtAllList(item) {
        var tempgrade = "";
        if (item == null) {
          tempgrade = this.form.grade;
        } else {
          tempgrade = item;
        }

        const idata = {
          sector: "30",
          grade: tempgrade,
          teacherid: this.form.teacherid,
          classid: this.classid,
        };

        manageService
          .getAllList(idata)
          .then((response) => {
            //this.manageLists = response.data;
            this.manageLists = response.data.slice(0, this.page);
            //console.log( this.nearMember);
            //this.manageLists = response.data;
            this.manageListLimit = response.data;
            this.pageTotalCount = Math.ceil(response.data.length / this.page);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      alertMsg(msg) {
        this.$toast.show(msg, {
          type: "warning",
          position: "top-right",
        });
      },
      //팀원 등록
      setMember(division) {
        //e.preventDefault();
        // var object = {};
        // var fform = document.getElementById("addMemberform");
        // var form = new FormData(fform);
        // form.forEach(function (value, key) {
        //   object[key] = value;
        //   //console.log(key, value);
        // });
        //var jsondata = JSON.stringify(object);

        var formdata = {
          groupid: this.form.groupid,
          gradediv: this.form.gradediv,
          name: this.form.name,
          sex: this.form.sex,
          birthday: this.form.birthday,
          phone: this.form.phone,
          parentphone: this.form.parentphone,
          address: this.form.address,
          rmk: this.form.rmk,
          teacherid: this.form.teacherid,
          school: this.form.school,
          div: division,
          no: this.form.no,
          classid: this.classid,
        };

        if (this.form.teacherid == undefined) {
          this.alertMsg("선생님을 선택하세요.");
          return;
        }
        if (this.form.groupid == undefined) {
          this.alertMsg("학년을 선택하세요.");
          return;
        }
        if (this.form.gradediv == undefined) {
          this.alertMsg("반을 선택하세요.");
          return;
        }
        if (this.form.name.length < 2) {
          this.alertMsg("이름을 입력하세요.");
          return;
        }
        if (this.form.sex == undefined) {
          this.alertMsg("성별을 선택하세요.");
          return;
        }
        if (this.form.name.birthday < 3) {
          this.alertMsg("생년월일을 입력하세요.");
          return;
        }
        if (this.form.name.phone < 5) {
          this.alertMsg("연락처를 입력하세요.");
          return;
        }
        if (this.form.name.parentphone < 5) {
          this.alertMsg("학부모 연락처를 입력하세요.");
          return;
        }

        manageService
          .register(formdata)
          .then(() => {
            // console.log(response.data);
            this.$toast.show("정상적으로 등록되었습니다.", {
              type: "success",
              position: "top-right",
            });
            this.getMgmtAllList(this.form.grade);
            $("#memberAddModal").modal("hide");
            this.formreset(); //초기화
          })
          .catch((e) => {
            console.log(e);
            this.$toast.show("등록되지 않았습니다.", {
              type: "warning",
              position: "top-right",
            });
          });
      },
      getTeacher(param) {
        const idata = {
          grade: this.form.grade,
          teacherid: param,
          role: this.role,
          classid: this.classid,
        };

        manageService
          .getteacher(idata)
          .then((response) => {
            this.teachers = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      UpdateMember(item) {
        //수정

        this.form.groupid = item.groupid;
        this.form.gradediv = item.grade.substr(1, 1);
        this.form.name = item.name;
        this.form.sex = item.sex;
        this.form.birthday = item.birthday;
        this.form.phone = item.phone;
        this.form.parentphone = item.parentphone;
        this.form.address = item.address;
        this.form.rmk = item.rmk;
        this.form.teacherid = item.teacherid;
        this.form.school = item.school;
        this.form.no = item.no;

        this.isNew = false;
        $("#memberAddModal").modal("show");
        console.log(item.grade);
      },
      formreset() {
        this.form.groupid = "";
        this.form.gradediv = "";
        this.form.name = "";
        this.form.sex = "";
        this.form.birthday = "";
        this.form.phone = "";
        this.form.parentphone = "";
        this.form.address = "";
        this.form.rmk = "";
        this.form.teacherid = "";
        this.form.school = "";
        this.form.no = "";
      },
    },
    created() {
      if (this.role > 2) {
        this.getMgmtAllList(this.form.grade);
      }

      this.getTeacher(this.form.teacherid);
      this.getGradeGroupList();
    },
  };
</script>
